<template>
  <div :class="isIncludeSideBar ? 'footSideBar' : 'footSideBar footNoSideBar'">
    <div class="left_warp">
      <p class="left_word">一键注册登录，免费获海量设计营销方案</p>
      <ul class="login_ad_list" v-if="loginBarAdList.length > 0">
        <li
          class="login_ad_item"
          v-for="(item, index) in loginBarAdList"
          :key="index"
        >
          <img :src="item.picUrl" width="16" height="16" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div @click="gotoLogin" class="login-button">
      <div class="login-button_wrapper">
        <span>立即登录</span>
        <div class="circle circle-12"></div>
        <div class="circle circle-11"></div>
        <div class="circle circle-10"></div>
        <div class="circle circle-9"></div>
        <div class="circle circle-8"></div>
        <div class="circle circle-7"></div>
        <div class="circle circle-6"></div>
        <div class="circle circle-5"></div>
        <div class="circle circle-4"></div>
        <div class="circle circle-3"></div>
        <div class="circle circle-2"></div>
        <div class="circle circle-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginLeadBar",
  props: {
    isIncludeSideBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loginBarAdList: [],
    };
  },
  mounted() {
    this.getLoginAd();
  },
  methods: {
    async getLoginAd() {
      try {
        this.$http
          .get("/hatchery/adposition", {
            data: {
              gw: true,
              requestMethod: "GET",
              template: true,
              nottrans: true,
            },
            params: {
              platform: 0,
              alias: "web_design_login",
              gw: true,
            },
          })
          .then(res => {
            const code = res.body.code;
            if (code === 200) {
              const data = res.body.data;
              if (Array.isArray(data)) {
                this.loginBarAdList = data;
                console.log(this.loginBarAdList, "loginBarAdList");
              }
            } else {
              console.warn(`数据查询失败，错误码：${code}`);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    gotoLogin() {
      this.$emit("gotoLogin");
    },
  },
};
</script>
<style lang="less">
@keyframes loadingRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footSideBar {
  position: fixed;
  z-index: 1000;
  left: calc(50% + 110px);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 24px;
  display: flex;
  width: 932px;
  height: 80px;
  padding: 0px 24px 0px 32px;
  border-radius: 16px;
  background: rgba(27, 35, 55, 0.8);
  backdrop-filter: blur(8px);
  &:hover{
    background: rgba(27, 35, 55, 0.9);
  }
  .left_warp {
    .left_word {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
    .login_ad_list {
      margin-top: 6px;
      display: flex;
      align-items: center;
      .login_ad_item {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 6px;
          color: #fff;
          font-size: 12px;
          line-height: 20px; /* 166.667% */
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .login_ad_item:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
  .login-button {
    display: flex;
    width: 140px;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 0.5px solid #fff;
    background: #c3d3ff;
    cursor: pointer;
    --duration: 7s;
    --easing: linear;
    --c-color-1: rgba(78, 255, 233, 0.7);
    --c-color-2: #1a23ff;
    --c-color-3: #7553FF;
    --c-color-4: rgba(86, 255, 234, 0.7);
    --c-shadow: rgba(22, 201, 180, 0.2);
    --c-shadow-inset-top: rgba(60, 255, 231, 0.9);
    --c-shadow-inset-bottom: rgba(106, 255, 237, 0.8);
    --c-radial-inner: #579DF7;
    --c-radial-outer: #579DF7;
    --c-color: #fff;
    outline: none;
    position: relative;
    cursor: pointer;
    border: none;
    display: table;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 1.5;
    color: var(--c-color);
    background: radial-gradient(
      circle,
      var(--c-radial-inner),
      var(--c-radial-outer) 80%
    );
    box-shadow: 0 0 14px var(--c-shadow);
    span {
      color: #fff;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .login-button:before {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    box-shadow: inset 0 3px 12px var(--c-shadow-inset-top),
      inset 0 -3px 4px var(--c-shadow-inset-bottom);
  }

  .login-button .login-button_wrapper {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    border-radius: 40px;
    min-width: 140px;
    padding: 10px 0;
  }

  .login-button .login-button_wrapper span {
    position: relative;
    z-index: 1;
  }

  .login-button:hover {
    --duration: 1400ms;
  }

  .login-button .login-button_wrapper .circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: blur(var(--blur, 8px));
    background: var(--background, transparent);
    transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
    animation: var(--animation, none) var(--duration) var(--easing) infinite;
  }

  .login-button .login-button_wrapper .circle.circle-1,
  .login-button .login-button_wrapper .circle.circle-9,
  .login-button .login-button_wrapper .circle.circle-10 {
    --background: var(--c-color-4);
  }

  .login-button .login-button_wrapper .circle.circle-3,
  .login-button .login-button_wrapper .circle.circle-4 {
    --background: var(--c-color-2);
    --blur: 14px;
  }

  .login-button .login-button_wrapper .circle.circle-5,
  .login-button .login-button_wrapper .circle.circle-6 {
    --background: var(--c-color-3);
    --blur: 16px;
  }

  .login-button .login-button_wrapper .circle.circle-2,
  .login-button .login-button_wrapper .circle.circle-7,
  .login-button .login-button_wrapper .circle.circle-8,
  .login-button .login-button_wrapper .circle.circle-11,
  .login-button .login-button_wrapper .circle.circle-12 {
    --background: var(--c-color-1);
    --blur: 12px;
  }

  .login-button .login-button_wrapper .circle.circle-1 {
    --x: 0;
    --y: -40px;
    --animation: circle-1;
  }

  .login-button .login-button_wrapper .circle.circle-2 {
    --x: 92px;
    --y: 8px;
    --animation: circle-2;
  }

  .login-button .login-button_wrapper .circle.circle-3 {
    --x: -12px;
    --y: -12px;
    --animation: circle-3;
  }

  .login-button .login-button_wrapper .circle.circle-4 {
    --x: 80px;
    --y: -12px;
    --animation: circle-4;
  }

  .login-button .login-button_wrapper .circle.circle-5 {
    --x: 12px;
    --y: -4px;
    --animation: circle-5;
  }

  .login-button .login-button_wrapper .circle.circle-6 {
    --x: 56px;
    --y: 16px;
    --animation: circle-6;
  }

  .login-button .login-button_wrapper .circle.circle-7 {
    --x: 8px;
    --y: 28px;
    --animation: circle-7;
  }

  .login-button .login-button_wrapper .circle.circle-8 {
    --x: 28px;
    --y: -4px;
    --animation: circle-8;
  }

  .login-button .login-button_wrapper .circle.circle-9 {
    --x: 20px;
    --y: -12px;
    --animation: circle-9;
  }

  .login-button .login-button_wrapper .circle.circle-10 {
    --x: 64px;
    --y: 16px;
    --animation: circle-10;
  }

  .login-button .login-button_wrapper .circle.circle-11 {
    --x: 4px;
    --y: 4px;
    --animation: circle-11;
  }

  .login-button .login-button_wrapper .circle.circle-12 {
    --blur: 14px;
    --x: 52px;
    --y: 4px;
    --animation: circle-12;
  }

  @keyframes circle-1 {
    33% {
      transform: translate(0px, 16px) translateZ(0);
    }

    66% {
      transform: translate(12px, 64px) translateZ(0);
    }
  }
  @keyframes circle-2 {
    33% {
      transform: translate(80px, -10px) translateZ(0);
    }

    66% {
      transform: translate(72px, -48px) translateZ(0);
    }
  }

  @keyframes circle-3 {
    33% {
      transform: translate(20px, 12px) translateZ(0);
    }

    66% {
      transform: translate(12px, 4px) translateZ(0);
    }
  }

  @keyframes circle-4 {
    33% {
      transform: translate(76px, -12px) translateZ(0);
    }

    66% {
      transform: translate(112px, -8px) translateZ(0);
    }
  }

  @keyframes circle-5 {
    33% {
      transform: translate(84px, 28px) translateZ(0);
    }

    66% {
      transform: translate(40px, -32px) translateZ(0);
    }
  }

  @keyframes circle-6 {
    33% {
      transform: translate(28px, -16px) translateZ(0);
    }

    66% {
      transform: translate(76px, -56px) translateZ(0);
    }
  }

  @keyframes circle-7 {
    33% {
      transform: translate(8px, 28px) translateZ(0);
    }

    66% {
      transform: translate(20px, -60px) translateZ(0);
    }
  }

  @keyframes circle-8 {
    33% {
      transform: translate(32px, -4px) translateZ(0);
    }

    66% {
      transform: translate(56px, -20px) translateZ(0);
    }
  }

  @keyframes circle-9 {
    33% {
      transform: translate(20px, -12px) translateZ(0);
    }

    66% {
      transform: translate(80px, -8px) translateZ(0);
    }
  }

  @keyframes circle-10 {
    33% {
      transform: translate(68px, 20px) translateZ(0);
    }

    66% {
      transform: translate(100px, 28px) translateZ(0);
    }
  }

  @keyframes circle-11 {
    33% {
      transform: translate(4px, 4px) translateZ(0);
    }

    66% {
      transform: translate(68px, 20px) translateZ(0);
    }
  }

  @keyframes circle-12 {
    33% {
      transform: translate(56px, 0px) translateZ(0);
    }

    66% {
      transform: translate(60px, -32px) translateZ(0);
    }
  }
}
.footNoSideBar {
  left: 0;
  right: 0;
  transform: none;
  margin: 0 auto;
}
</style>
