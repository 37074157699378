<template>
  <div class="service_Modal" v-if="vipLevelModalState">
    <div
      class="vip-item-box"
      v-for="item in enterServiceList"
      :key="item.id"
      @click="openPage(item)"
      v-show="!(isLenovoSite&&item.id===4)"
    >
      <div class="img-container">
        <img :src="item.img" />
      </div>
      <div class="des-box">
        <div class="title">
          <img class="vip-icon" :src="item.vipIcon" />
          <span>{{ item.title }}</span>
          <!-- <img
            src="./img/free_icon.svg"
            class="free-icon"
            v-if="showVipFree && item.id == 0"
          /> -->
          <span v-if="showHalfPriceTip && item.id === halfPriceLevel" class="red-tips">{{modalData.subscriptInfo}}</span>
          <span class="arrow-name"></span>
        </div>
        <div class="des">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VipLevelModal',
    components: {},
    props: {
      vipLevelModalState: {
        type: Boolean,
        default: false,
      },
      showActiveIcon: {
        type: Boolean,
        default: false,
      },
      teamInfo: {
        type: Object,
        default() {
          return {};
        },
      },
      modalData: {
        type: Object,
        default() {
          return {};
        },
      },
      halfPriceTimeOut: {
        type: Boolean,
        default: false
      },  
    },
    data() {
      return {
        enterServiceList: [
          {
            img: require('./img/standar_pic.png'),
            vipIcon: require('./img/standar_icon.svg'),
            title: '通用版',
            moduleName: 'hover_tybvip',
            desc: '适合个人或企业1人使用，畅享无水印高清下载。',
            href: '',
            id: 0,
          },
          {
            img: require('./img/premium-pic.png'),
            vipIcon: require('./img/premium_icon.svg'),
            title: '协作版',
            moduleName: 'hover_xzbvip',
            desc: '适合企业2～10人使用，资源共享/在线协作，企业商用授权。',
            href: '',
            id: 1,
          },
          {
            img: require('./img/flag-pic.png'),
            vipIcon: require('./img/flag_icon.svg'),
            title: '旗舰版',
            desc: '适合10人以上大型企业/多品牌/多连锁分支机构使用，功能可定制、商业授权范围广。',
            href: '/contech?semPosition=旗舰版咨询-顶栏会员',
            id: 4,
          },
        ],
      };
    },
    computed:{
      showHalfPriceTip(){
        return (this.modalData.switchStatus===2||this.modalData.switchStatus===3) && !this.halfPriceTimeOut
      },
      halfPriceLevel(){
        if(this.modalData){
          const sandard = [199,200,210].includes(this.modalData.level)
          const premium = [203,204].includes(this.modalData.level)
          return sandard?0:premium?1:0
        }
        return 0
      },
      showVipFree(){
        return this.showActiveIcon && this.teamInfo.teamRoleEnum ==='Administrator' && this.teamInfo.adminUserVipLevel === 0 && !location.pathname.includes('/design-service')
      },
      isLenovoSite(){
        const sourceName = localStorage.getItem("utm_source") 
        const isLenovoPc = sourceName === 'lxllq' || sourceName === 'lxllqsy' || sourceName === 'lxllqAIzl' || sourceName === '联想浏览器名站首页' || sourceName === '联想浏览器AI专栏'
        console.log('isLenovoPc',isLenovoPc)
        return window.jsbridge || isLenovoPc ? 1 : 0
      },
    },
    methods: {
      openPage(item) {
        if (item.href) {
          window.open(item.href);
        } else {
          const freeParams =
            localStorage.getItem('tariffPackageParams') &&
            JSON.parse(localStorage.getItem('tariffPackageParams'));
          console.log('freeParams', freeParams);
          const params = {
            source: {
              pageName: 'topbar',
              moduleName: item.moduleName,
            },
            standardOrEnterprise: item.id === 1 ? 'enterprise':'standard',
            tariffPackageParasm: freeParams ? freeParams : { tariffPackageId: 0,guidExplain: ""},
          };
          //点击的按钮类型与特价套餐level值对应 才传参
          const isStandType = item.id === 0 && [199, 200, 210].includes(this.modalData.level)
          const isEnterpriseType = item.id === 1 && [203, 204].includes(this.modalData.level)
          if(isStandType || isEnterpriseType){
            params.packageLevel = this.modalData.level
            params.packageId = this.modalData.packageId
          }
          sessionStorage.setItem(
            'forward',
            JSON.stringify({
              forward_module_name: item.moduleName,
              forward_page_name: 'topbar',
            }),
          );
          this.$emit('rights-buy-pop', params);
        }
      },
      enterPrise() {
        this.$emit('enetrExtraInfo');
      },
    },
  };
</script>

<style lang="less" scoped>
  .service_Modal {
    width: 397px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    top: 47px;
    right: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 6px 24px 4px rgba(27, 35, 55, 0.08);
    .vip-item-box {
      width: 100%;
      display: flex;
      cursor: pointer;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .img-container {
        width: 136px;
        height: 92px;
        overflow: hidden;
        border-radius: 8px;
        img {
          width: 136px;
          height: 92px;
          border-radius: 8px;
          transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
          will-change: transform;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .des-box {
        width: 205px;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          width: 100%;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          font-weight: 500;
          color: #1b2337;
          position: relative;
          display: flex;
          align-items: center;
          .vip-icon{
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
          .red-tips{
            display: inline-block;
            position: absolute;
            left: 80px;
            top: 0px;
            height: 16px;
            line-height: 16px;
            font-size: 9px;
            font-weight: 500;
            color: #fff;
            background: linear-gradient(90deg, #FF6464 2.95%, #FA2323 99.68%);
            border-radius: 9px 8px 8px 0px;
            text-align: center;
            padding: 0 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .arrow-name {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 18px;
            background: url(./img/arrow_icon.svg);
            visibility: hidden;
          }
          .free-icon {
            width: 56px;
            height: 16px;
            margin-left: 8px;
          }
        }
        .des {
          width: 100%;
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #8693ab;
        }
      }
      &:hover {
        .title {
          .arrow-name {
            visibility: visible;
            transition: right 0.3s, opacity 0.3s;
            right: 0;
          }
        }
      }
    }
  }
</style>
