<template>
    <div class="create-team-modal-warp">
        <div class="modal-left" v-if="activityEntryShow" @click="toActivityPage">
            <img :src="imgUrl" />
        </div>
        <div class="modal-right">
            <div class="set_team" v-if="!setChange">
                <div class="section section_bg">
                    <p>创建团队/企业</p>
                    <div>根据品牌风格打造个性化团队，让所有团队成员高效协作</div>
                </div>
                <div class="section">
                    <p>团队/企业名称</p>
                    <input class="team_input" :maxlength="30" v-model="companyName" placeholder="请输入您的公司/团队名称" @input="changeValue"  @keyup="replaceValue"/>
                </div>
                <div class="section">
                    <p>预估使用人数</p>
                    <ul class="company_type_list use_p_list">
                        <li class="company_type use_p" :class="{active:item.active}" @click="selectPersonItem(item,index)" v-for="(item,index) in estimateNumber" :key="item.value">
                            {{ item.description }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="" v-if='setChange'>
                <div class="section">
                    <p>设置行业和用途，获取精准推荐</p>
                    <div>设置后可在推荐页获取到精准的内容推荐，可随时修改</div>
                </div>
                <div class="middle_con">
                    <div class="middle_con_section">
                        <div class="industry_top">
                            <span>所属行业</span>
                            <span>多选({{industry_num}}/3)</span>
                        </div>
                        <ul :class="isShowBtn?'industry_list industry_list_show':'industry_list'">
                            <li class="industry_item" :class="{active:item.activeState}" @click="handleSelectIndustry(item,index)" v-for="(item,index) in industryList" :key="index">
                            {{ item.description }}</li>
                        </ul>
                        <div class="team-tag-select__toggle" v-if="industryList.length>5"  @click="openBtn">
                            <div class="team-tag-select__wrap">
                                <lt-icon
                                :type="isShowBtn? 'ios-arrow-up' : 'ios-arrow-down'"
                                color="#494F5F"
                                :size="8"
                                class="nav-item__arrow"
                                />
                                <span>{{!isShowBtn?"展开":"收起"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="middle_con_section">
                        <div class="industry_top">
                            <span>设计用途</span>
                            <span>多选({{use_num}}/3)</span>
                        </div>
                        <ul class="industry_list">
                            <li class="industry_item" :class="{active:item.activeState}" @click="handleSelectUse(item,index)" v-for="(item,index) in useList" :key="index">
                            {{ item.description }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div :class="!isEdiet&&!isShowBtn?'bottom_con':'bottom_con_shadow'">
                <div class="btn_warp" v-if="setChange">
                    <button class="pre-step" @click="goToPre" v-if="!isEdiet">上一步</button>
                    <button class="complete" @click="completeBtn">完成</button>
                </div>
                <button class="next-btn" v-if="!setChange" :class="chooseAll? 'next-btned':''"  @click="goToNext">下一步</button>
                <div class="ext-wrod">{{!isEdiet?'你可以随时修改团队/企业信息':''}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
let industry = [];
let purpose = [];
export default {
    name: "CreateTeamForm",
    data() {
        return {
            companyName: '',
            modalInfo:{},
            industryList:[],//行业列表
            useList:[],//用途列表
            estimateNumber:[],//预估使用人数
            imgUrl:'',
            activityEntryShow:'',
            activityJumpUrl:'',
            typeIndex:null,
            activeState:false,
            chooseAll:false,
            chooseItems:false,
            setChange:false,
            isShowBtn:false,
            industry_num:0, //行业选中数量
            use_num:0, //用途选中数量
            active_estimateValue:'', //选中的人数
            industry:'', //选中的行业
            purpose:'' //选中的用途

        }
    },
    props: {
        userInfo: {
            type: Object,
            default: {}
        },
        isLogin:{
            type: Boolean,
        },
        isEdiet:{
            type: Boolean,
            default: false
        },
        customData:{
            type: Object,
            default: {'industry':[],'purpose':[]}
        }
    },
    mounted() {
        this.getUserName()
        this.getBannerPictureInfo()//获取运营位广告图
        this.getConfig()//获取偏好配置
        if(this.isEdiet){
            this.goNextState()
        }
    },
    methods: {
        ...mapActions(['setBannerInfo']),
        async getConfig() {
            try {
                const { body: { code, data } = {} } = await this.$http.get('/team/fuse/custom/getConfig', {
                    params: {gw: true},
                    data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
                })
                if (code === 200) {
                    this.estimateNumber =data.estimateNumber
                    this.industryList =data.industry
                    this.useList =data.purpose
                    purpose=[]
                    industry=[]
                    if(this.customData.industry.length>0){
                        data.industry.length>0&&data.industry.map((v,index)=>{
                            const targetElement = data.industry[index].description;
                            if(this.customData.industry.includes(targetElement)){
                                this.$set(v,'activeState',true)
                                industry.push(v.description)
                                this.industry_num++ 
                                this.industry = industry.join(',')
                            }
                        })
                    }

                    if(this.customData.purpose.length>0){
                        data.purpose.length>0&&data.purpose.map((v,index)=>{
                            const targetElement = data.purpose[index].description;
                            if(this.customData.purpose.includes(targetElement)){
                                this.$set(v,'activeState',true)
                                purpose.push(v.description)
                                this.use_num++
                                this.purpose =purpose.join(',')
                            }
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        getUserName(){
            if(this.userInfo.nickname&&this.userInfo.nickname.length>=30){
                this.companyName = this.checkName(this.userInfo.nickname).substring(0,27)+'的团队'||''
            }else{
                this.companyName = this.checkName(this.userInfo.nickname)+'的团队'||''
            }
            
        },
        // 获取活动banner顶图
        async getBannerPictureInfo(){
            try {
                const  {
                body: { code, data }  
                } = await this.$http.get(
                '/hatchery/adposition',
                {
                data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
                params: { platform: 0, alias:'web_team_create', gw: true },
                })
                if (code === 200 ){
                this.activityEntryShow = typeof data !== 'undefined' && data.length
                this.imgUrl = data &&data.length>0&& data[0].picUrl
                this.activityJumpUrl = data &&data.length>0 && data[0].jumpUrl
                }
            } catch (e) {
                console.error('error \n' + e)
            }
        },
        toActivityPage(){
            if(this.activityJumpUrl){
                window.open(this.activityJumpUrl);
            }
        },
        async goToNext(){
            if(!this.activeState || !this.companyName) {
                this.$message('请完善团队/企业信息后再继续')
                return
            }
            if(!this.isLogin) return
            this.$emit('createTeam',this.companyName)
        },
        goToPre(){
            this.setChange=false
        },
        goNextState(){
            this.setChange=true
        },
        completeBtn(){
            this.saveTeamInfo()
        },
        openBtn(){
            this.isShowBtn = !this.isShowBtn
        },
        async saveTeamInfo(){
            if(this.isEdiet){
                this.companyName = ''
                this.active_estimateValue = ''
            }
            try {
                const { body: { code, data } = {} } = await this.$http.get('/team/fuse/custom/save', {
                    params: {gw: true, nottrans: true, typeDesc:this.companyName,teamId:this.isEdiet?this.userInfo.curTid:'',
                        estimateNumber:this.active_estimateValue,
                        industry:this.industry,
                        purpose:this.purpose, },
                    data: { gw: true, requestMethod: "GET", template: true, nottrans: true },
                })
                if (code === 200) {
                    this.$emit('closeModal')
                }
            } catch (error) {
                console.log(error)
            }
        },
        selectPersonItem(item,index){
            this.active_estimateValue =item.description
            if(item.active){
                this.$set(item,'active',false)
            }else{
                this.estimateNumber.map(v=>{
                    this.$set(v,'active',false)
                })
                this.$set(item,'active',true)
            }
            this.activeState = item.active
            if(this.companyName && this.activeState){
                this.chooseAll =true
            }else{
                this.chooseAll =false
            }

        },
        changeValue(e) {
            this.companyName = e.target.value
            if (this.isIncludeEmoji(this.companyName)){
                const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF\u2764\u2648-\u26F5\u2702\u2600-\u2B55]+/g;
                this.companyName=  this.companyName.replace(emojiRegex, '');
            }
            if(this.companyName && this.activeState){
                this.chooseAll =true
            }else{
                this.chooseAll =false
            }
        
        },
        replaceValue(){
            this.companyName =this.checkName(this.companyName)
            if(this.companyName && this.activeState){
                this.chooseAll =true
            }else{
                this.chooseAll =false
            }
        },
        checkName(name){
            const reg = /[%^&*\s]/
            if (reg.test(name)){
                return name.replace(/[%^&*\s]/gi, '')
            } else {
                return name
            }
            
        },
        isIncludeEmoji(item){
            let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/;
            if (reg.test(item)){
                return true
            } else {
                return false
            }
        },
        handleSelectIndustry(item,index){
            if(item.activeState){
                let industryIndex =industry.findIndex((element) => element === item.description)
                industry.splice(industryIndex,1)
                this.industry_num--
                this.$set(item,'activeState',false)
            }else{
                if(this.industry_num>=3) {
                    this.$message(`为更精准推荐内容，最多只能选3个选项`);
                    return
                }
                industry.push(item.description)
                this.industry_num++
                this.$set(item,'activeState',true)
            }
            this.industry= industry.join(',')
            if(this.industry_num && this.use_num){
                this.chooseItems =true
            }else{
                this.chooseItems =false
            }
        },
        handleSelectUse(item,index){
            if(item.activeState){
                // this.purpose-= `${item.description},`
                let purposeIndex =purpose.findIndex((element) => element === item.description)
                purpose.splice(purposeIndex,1)
                this.use_num--
                this.$set(item,'activeState',false)
            }else{
                if(this.use_num>=3) {
                    this.$message(`为更精准推荐内容，最多只能选3个选项`);
                    return
                }
                // this.purpose+= `${item.description},`
                purpose.push(item.description)
                this.use_num++
                this.$set(item,'activeState',true)
            }
            this.purpose= purpose.join(',')
            if(this.industry_num && this.use_num){
                this.chooseItems =true
            }else{
                this.chooseItems =false
            }
        }
    }
}
</script>
<style scoped lang="less">
.create-team-modal-warp {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #505A71;

    .modal-left {
        width: 320px;
        height: 524px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 4px 0 0 4px;
        }
    }

    .modal-right {
        // padding-bottom: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 496px;
        height: 524px;
        box-sizing: border-box;
        .section {
            padding:0 32px;
            margin-top: 20px;
        }

        .section:first-child {
            margin-top: 0px;
            padding-top: 56px;
            height: 132px;
            background: url(../../../img/team_bg.svg);
        }

        .section:nth-child(2) {
            margin-top: 0;
        }

        .section p {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #1B2337;
            margin-bottom: 12px;

        }

        .section div {
            line-height: 20px;
        }

        .section:first-child div {
            margin-top: 6px;
        }

        .section:first-child p {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 0;
        }
        .middle_con{
            max-height: 298px;
            padding: 0 0 10px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .middle_con_section{
            padding: 0 32px;
            &:last-child{
                margin-top: 20px;
            }
            &:first-child .industry_list{
               height: 78px;
            }
        }
        .industry_top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:first-child{
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #1B2337
            }
            
        }
        .industry_list{
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            margin: 12px -6px -6px;
            .industry_item{
                display: inline-flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                padding: 5px 8px;
                margin: 0 6px 8px;
                overflow: hidden;
                font-size: 14px;
                height: 32px;
                line-height: 22px;
                background: #F8F8FB;
                border-radius: 4px;
                cursor: pointer;
                user-select: none;
                transition: all .3s ease;
            }
            .active{
                background: #E6F1FE;
                color: #0773FC;
            }
        }
        .industry_list_show{
            height: auto !important;
            overflow: visible;
        }
        .team-tag-select__toggle{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            .team-tag-select__wrap{
                min-width: 54px;
                font-size: 12px;
                color: #7f8792;
                text-align: center;
                cursor: pointer;
                span{
                    margin-left: 6px;
                }
            }
        }
        .team-tag-select__toggle:before, .team-tag-select__toggle:after {
            width: 100%;
            height: 1px;
            background-color: #f1f2f4;
            content: "";
        }
        .team_input{
            width: 100%;
            height: 42px;
            padding: 0 32px 0 16px;
            background: #fff;
            border: 1px solid rgba(0,0,0,.1);
            border-radius: 4px;
            font-size: 14px;
            outline: none;
        }
        
        .company_type_list {
            display: flex;
            align-items: center;
            .company_type {
                padding: 0 8px;
                height: 32px;
                line-height: 32px;
                background: #F1F3F9;
                border-radius: 4px;
                margin-right: 12px;
                cursor: pointer;

            }
            .active{
                background: #E6F1FE;
                color: #0773FC;
            }

            & li:last-child {
                margin-right: 0;
            }
        }
        .bottom_con{
            padding: 0 32px 32px;
        }
        .bottom_con_shadow{
            padding: 0 32px 32px;
            box-shadow: 0px -6px 24px rgba(42, 49, 67, 0.1);
            border-radius: 0px 0px 4px 4px;  
        }
        .btn_warp{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 6px;
            .pre-step{
                width: 90px;
                height: 38px;
                background: #FFFFFF;
                border: 1px solid #EBEEF5;
                border-radius: 4px;
                cursor: pointer;
                margin-right: 16px;
                &:hover{
                    border: 1px solid #C5C8CE;
                }
            }
            .complete{
                width: 100%;
                height: 40px;
                color: #fff;
                font-size: 14px;
                border-radius: 4px;
                cursor: pointer;
                background-color: #0773FC;
                border: 1px solid #0773FC;
                &:hover{
                    background-color: #0667E2;
                    border: 1px solid #0667E2;
                }
            }
        }
        
        .next-btn{
            width: 100%;
            height: 42px;
            border: 1px solid #B4D5FE;
            color: #fff;
            font-size: 14px;
            border-radius: 4px;
            background-color: #B4D5FE;
            margin-top: 32px;
        }
        .next-btned{
            background-color: #0773FC;
            border: 1px solid #0773FC;
            cursor: pointer;
            &:hover{
                background-color: #0667E2;
                border: 1px solid #0667E2;
            }
        }
        .ext-wrod{
            color: #8693AB;
            margin-top: 12px;
            font-size: 12px;
        }

    }
}</style>